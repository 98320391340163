import { IonButton, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonModal, IonRow, IonSpinner, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../services/app-context.service';
import { statusWishlistProduct, toggleWishlistProduct } from '../../services/account/wishlist.service';
import { heart, heartOutline } from 'ionicons/icons';
import Product from '../../types/product.type';
import { trackEvent } from '../../services/tracking.service';
type Props = {
    product: Product
}
const ProductWishlistButton: React.FC<Props> = ({ product }) => {
    const [inWishlist, setInWishlist] = useState(false)
    const [WishlistBtnDisabled, setWishlistBtnDisabled] = useState(false)
    const { user } = useAppContext()
    useEffect(() => {
        if (user.token) {
            statusWishlistProduct(product.id).then((res) => {
                setInWishlist(res.status)
            })
        }
    }, [product])
    function onToggle() {
        if(inWishlist){
            trackEvent('RemoveFromWishlist', {
                content_name: product.name,
                content_category: product.category_title,
                content_ids: [product.id],
                content_type: 'product',
                value: product.sale_price,
                currency: 'BDT'
            });
        }else{
            trackEvent('AddToWishlist', {
                content_name: product.name,
                content_category: product.category_title,
                content_ids: [product.id],
                content_type: 'product',
                value: product.sale_price,
                currency: 'BDT'
            });
        }
        setWishlistBtnDisabled(true)
        toggleWishlistProduct(product.id).then((res) => {
            setInWishlist(res.type == 'success')
        }).finally(() => {
            setWishlistBtnDisabled(false)
        })
    }
    if (!user.token) {
        return null
    }

    return <IonButton onClick={onToggle} disabled={WishlistBtnDisabled} fill='outline' color='danger'>
        {WishlistBtnDisabled ? <IonSpinner /> : <IonIcon icon={inWishlist ? heart : heartOutline} />}
    </IonButton>
};

export default ProductWishlistButton;
