import { IonCol, IonContent, IonRow } from '@ionic/react';
import FlashSale from '../components/Home/FlashSale';
import HalfDiscount from '../components/Home/HalfDiscount';
import { useEffect, useState } from 'react';
import HomeSection from '../types/home.type';
import { apiBaseUrl, appSubDir, baseUrl } from '../configs/app';
import CategorySwiper from '../components/Home/CategorySwiper';
import SlidesMini from '../components/Home/SlidesMini';
import ProductsHorizontal from '../components/Products/ProductsHorizontal';
import Header from '../components/Header/Header';
import ProductsGrid from '../components/Products/ProductsGrid';
import AllCategories from '../components/Home/AllCategories';
import './Home.scss';
import CategorySwiperVerticle from '../components/Home/CategorySwiperVerticle';
import Footer from '../components/Footer';
import { onScroll } from '../services/sticky-menu.service';
import HomeItemLink from '../components/Home/HomeItemLink';
import CategoryProducts from '../components/Products/CategoryProducts';
import CryptoService from '../services/crypto.services';
import loginService from '../services/loginService';
import ReactPixel from 'react-facebook-pixel';
import { trackEvent } from '../services/tracking.service';
const localStorageKey = CryptoService.hash("home-screen-data")
const Home: React.FC = () => {
    const localData = window.localStorage.getItem(localStorageKey)
    const [items, setItems] = useState((localData ? JSON.parse(CryptoService.decrypt(localData)) : []) as HomeSection[])
    function fetchData() {
        const urlPart = "app-data/home-screen?items_per_page=24&img=web"
        let promise: Promise<Response>
        if (loginService.currentUser.token) {
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + loginService.currentUser.token);

            const requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            } as RequestInit;
            promise = fetch(apiBaseUrl + "private-" + urlPart, requestOptions)
        } else {
            promise = fetch(apiBaseUrl + urlPart)
        }
        promise.then(response => response.json()).then(result => {
            setItems(result.data)
            // console.log(result.data)
            window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(result.data)))

        }).catch(error => console.log('error', error));
    }
    useEffect(() => {
        const timeout = setTimeout(() => {
            trackEvent("Home Page");
            fetchData()
        }, 10)
        return () => clearTimeout(timeout)
    }, [])
    let bannerCount = 0
    function getBannerRow(index: number) {
        bannerCount++
        const localItems = items.filter(item => item.type === "Banner" && item.items && item.items[0].file_path).slice((index * 3), index * 3 + 3)
        if (localItems.length === 3) {
            return <IonRow style={{ marginBottom: 50 }}>
                {items.filter(item => item.type === "Banner" && item.items && item.items[0].file_path).slice((index * 3), index * 3 + 3)
                    .map((item2, index2) => {
                        if (!item2.items || !item2.items[0]) {
                            return null
                        }
                        const banner = item2.items[0]
                        return <IonCol key={index2} size='12' sizeMd='4'>
                            <HomeItemLink item={banner}>
                                <img src={baseUrl + banner.file_path} />
                            </HomeItemLink>
                        </IonCol>
                    })}
            </IonRow>
        }
    }
    return (
        <>
            <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
                <Header />
                <IonRow>
                    <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                        {window.screen.width > 768 && <IonRow className='home-slider mb-50'>
                            <IonCol sizeLg='3'>
                                <AllCategories />
                            </IonCol>
                            <IonCol sizeLg='6'>
                                <SlidesMini slides={items.find(item => item.type === 'Slider')?.items} />
                            </IonCol>
                            <IonCol sizeLg='3'>
                                <CategorySwiperVerticle data={items.find(item => item.type === 'Swiper')} />
                            </IonCol>
                        </IonRow>}
                        {(768 >= window.screen.width && window.screen.width > 600) && <IonRow className='home-slider mb-50'>
                            <IonCol sizeMd='5'>
                                <AllCategories />
                            </IonCol>
                            <IonCol sizeMd='7'>
                                <SlidesMini slides={items.find(item => item.type === 'Slider')?.items} />
                            </IonCol>
                        </IonRow>}
                        {window.screen.width <= 600 && <IonRow className='home-slider mb-50'>
                            <IonCol sizeMd='7'>
                                <SlidesMini slides={items.find(item => item.type === 'Slider')?.items} />
                            </IonCol>
                        </IonRow>}
                        {window.screen.width <= 768 && <CategorySwiper data={items.find(item => item.type === 'Swiper')} />}
                        {getBannerRow(3)}
                        <FlashSale products={items.find(item => item.type === 'Flash Sale')?.products?.data} />
                        {getBannerRow(1)}
                        <ProductsHorizontal title='New Arrival' products={items.find(item => item.type === 'New Arrival')?.products?.data} delay={4500} routerLink={appSubDir + "new-arrival"} />
                        {getBannerRow(0)}
                        <IonRow>
                            <IonCol sizeLg='4'>
                                <CategoryProducts catId={3} title='Beauty Products' />
                            </IonCol>
                            <IonCol sizeLg='4'>
                                <HalfDiscount />
                            </IonCol>
                            <IonCol sizeLg='4'>
                                <CategoryProducts catId={11} title='Stock Deals' />
                            </IonCol>
                        </IonRow>
                        <ProductsGrid title='All Products' products={items.find(item => item.type === 'All Products')?.products?.data} />
                        {getBannerRow(4)}
                    </IonCol>
                </IonRow>
                <Footer />
            </IonContent>
        </>
    );
};

export default Home;
